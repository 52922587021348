。<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">补考订单统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl searchboxfl">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="search.userName"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div title="手机号码" class="searchboxItem ci-full">
              <span class="itemLabel">手机号码:</span>
              <el-input
                v-model="search.mobile"
                type="text"
                size="small"
                placeholder="请输入手机号码"
                clearable
              />
            </div>
            <div title="支付方式" class="searchboxItem ci-full">
              <span class="itemLabel">支付方式:</span>
              <el-select
                v-model="search.paymentMethod"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in paymentMethodList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="交易状态" class="searchboxItem ci-full">
              <span class="itemLabel">交易状态:</span>
              <el-select
                v-model="search.orderStatus"
                placeholder="请选择交易状态"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in OrderStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel">订单日期:</span>
              <el-date-picker
                v-model="search.orderDate"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="支付日期" class="searchboxItem ci-full">
              <span class="itemLabel">支付日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="search.PaymentDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="getExport()"
              >导出</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="订单编号"
                align="center"
                show-overflow-tooltip
                prop="orderSn"
                fixed
                min-width="120px"
              />
              <el-table-column
                label="订单支付编号"
                align="center"
                show-overflow-tooltip
                prop="orderPaymentNumber"
                width="120px"
              />
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="支付方式"
                align="center"
                show-overflow-tooltip
                prop="paymentMethod"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("PAY_METHOD", scope.row.paymentMethod) }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单时间"
                width="100px"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="支付时间"
                width="100px"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.payTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="支付金额"
                align="center"
                show-overflow-tooltip
                prop="paymentMoney"
              />
              <el-table-column
                label="交易状态"
                align="center"
                show-overflow-tooltip
                prop="orderStatus"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("ORDER_STATUS", scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/classStart",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      search: {
        userName: "", //姓名
        mobile: "", //手机号
        orderStatus: "", //交易状态
        paymentMethod: "", //支付方式
        orderDate: "", // 订单时间
        PaymentDate: "", //支付时间
      },
      OrderStatusList: [],
      paymentMethodList: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getMzList();
  },
  methods: {
    getMzList() {
      let orderList = this.$setDictionary("ORDER_STATUS", "list");
      let paymentList = this.$setDictionary("PAY_METHOD", "list");
      for (const key in orderList) {
        if (key == "0" || key == "1") {
          this.OrderStatusList.push({
            value: key,
            label: orderList[key],
          });
        }
      }
      for (const key in paymentList) {
        if (key == "1" || key == "3") {
          this.paymentMethodList.push({
            value: key,
            label: paymentList[key],
          });
        }
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.search.userName) {
        params.userName = this.search.userName;
      }
      if (this.search.mobile) {
        params.mobile = this.search.mobile;
      }
      if (this.search.orderStatus) {
        params.orderStatus = this.search.orderStatus;
      }
      if (this.search.paymentMethod) {
        params.paymentMethod = this.search.paymentMethod;
      }
      if (this.search.orderDate) {
        params.orderTimeBegin = this.search.orderDate[0];
        params.orderTimeEnd = this.search.orderDate[1];
      }
      if (this.search.PaymentDate) {
        params.payTimeBegin = this.search.PaymentDate[0];
        params.payTimeEnd = this.search.PaymentDate[1];
      }
      this.doFetch({
        url: "/biz/makeUpOrder/pageList",
        params,
        pageNum,
      });
    },
    getExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {};
          if (this.search.userName) {
            params.userName = this.search.userName;
          }
          if (this.search.mobile) {
            params.mobile = this.search.mobile;
          }
          if (this.search.orderStatus) {
            params.orderStatus = this.search.orderStatus;
          }
          if (this.search.paymentMethod) {
            params.paymentMethod = this.search.paymentMethod;
          }
          if (this.search.orderDate) {
            params.orderTimeBegin = this.search.orderDate[0];
            params.orderTimeEnd = this.search.orderDate[1];
          }
          if (this.search.PaymentDate) {
            params.payTimeBegin = this.search.PaymentDate[0];
            params.payTimeEnd = this.search.PaymentDate[1];
          }
          this.$post("/biz/makeUpOrder/listExport", params)
            .then((res) => {
              if (res.status == "0") {
                let list = [];
                list.push(res.data);
                for (let item of list) {
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(re.message);
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {});
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
